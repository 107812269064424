import React from 'react';
import style from '.././css/style.css';

const Header = () => {
  return (
   
<header className="header" style={{background:"#212529"}} >

<div id="menu-btn" className="fas fa-bars"></div>

<a data-aos="zoom-in-left" data-aos-delay="150" href="tel:5554280940"className="logo"> <i className="fas fa-paper-plane"></i><img src='https://i.imgur.com/Cf3v9Sp.png' style={{height:"80px",width:"200px"}}/> </a>

<nav class="navbar">
    <a data-aos="zoom-in-left" data-aos-delay="300" href="#home">home</a>
    <a data-aos="zoom-in-left" data-aos-delay="450" href="#floorplan">FloorPlan</a>
    <a data-aos="zoom-in-left" data-aos-delay="600" href="#pricelist">PriceList</a>
    <a data-aos="zoom-in-left" data-aos-delay="750" href="#features">Features</a>
    <a data-aos="zoom-in-left" data-aos-delay="900" href="#location">Location</a>
    <a data-aos="zoom-in-left" data-aos-delay="1150" href="#video">Video</a>
</nav>


<div style={{display:"block"}}>
  <div style={{color:"white",fontSize:"12px",textAlign:"center"}}>Get in Touch</div>
  <div ><a href="tel:8130306040" style={{color:"#f1cc80",fontSize:"16px",textAlign:"center"}}>+91-81-3030-6040</a></div>
  
</div>
</header>

    
  )
}

export default Header;