import React from 'react'

const Video = () => {
  return (
    <div >
<section className="destination" id="video" style={{marginTop:"5px"}}>

<div className="heading">
    
    <h3 style={{color:"black",fontSize:"25px",textDecoration:"underline"}}>Parx Laureate Virtual Tour</h3>
</div>

<div className="box-container" style={{height:"350px"}}>

    <div className="box" data-aos="fade-up"  data-aos-delay="150">
       
       <iframe width="100%" height="100%" src="https://www.youtube.com/embed/CJoKqDDMEww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           
        
        
    </div>



 


   

    
  </div>

</section>




    </div>
  )
}

export default Video