import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {Dialog} from '@mui/material'
import {useNavigate} from 'react-router-dom';
const Dialogbox = () => {
   
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate=useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fm803ag', 'template_n62qg0m', form.current, 'sdsKJg8kQr7k_DIFw')
      .then((result) => {
          console.log(result.text);
          navigate("/Thankyou")
      }, (error) => {
          console.log(error.text);
      });
  };
 
  

  return (
    <div >
      
      <div ><button className="neonbtn" style={{zIndex:'100',marginLeft:"0%",marginTop:"15px",width:"350px",borderRadius:"30px" }}  onClick={handleClickOpen}><h4 className="neon" style={{zIndex:'110',position:'relative',textAlign:'center'}}>Download Brochure</h4></button></div>
      <Dialog open={open} onClose={handleClose}>
     
      <div >
      <div>
        <div>
          <div style={{height:"320px",width:"280px",border:"5px solid #c1a356",textAlign:"center"}}>
            <h3 style={{marginTop:"25px",fontSize:"20px"}}>To Download Brochure</h3>
           
            <div >
           
      
            <form ref={form} onSubmit={sendEmail}>
    
      <input type="text" name="user_name" placeholder='Enter Your Name' required style={{height:"50px",width:"250px",border:"1px solid black",margin:"5px"}}/>
      
      <input type="text"  name="contact_number" placeholder='Enter Your Contact number' required title="Error Message" pattern="[1-9]{1}[0-9]{9}" style={{height:"50px",width:"250px",border:"1px solid black",margin:"5px"}}/>
     

      <input type="submit" value="Send" style={{height:"35px",width:"100px",margin:"5px",background:"#c1a356",color:"white",fontSize:"20px",borderRadius:"5px",padding:"1px"}}/>

    </form>
 
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
 
       
      </Dialog>
    </div>
  );
}

export default Dialogbox ;