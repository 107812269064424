import FloorPlan from "./FloorPlan/FloorPlan";
import Header from "./Header/Header";
import Price from "./Price/Price";
import Footer from "./Footer/Footer";
import Map from "./map/Map";
import Slider from "./Slider/Slider";
import Slider2 from "./Slider/Slider2"
import Grid from "@mui/material/Grid";
import Video from "./Video/Video";
import Sidebar from "./Sidebar/Sidebar";
import Bottom from './Sidebar/Bottom';
import Why from "./Why/Why";
import Dialog from "./Why/Dialog"
function App() {
  return (
    <div>
      <Grid container>
        <Grid xs={12} lg={12}>
          <Header />
        </Grid>
        <Grid xs={0} lg={9} sm={0} md={0} >
          <Slider />
        </Grid>
        <Grid xs={0} sm={0} md={0} lg={3}>
          <Sidebar />
        <Grid xs={12} lg={0} sm={12} md={12} >
          <Slider2 />
        </Grid>
        
        </Grid>
        <Grid xs={12} lg={9} sm={12} md={12}>
          <Why />
        </Grid>
        <Grid  xs={12} lg={9} sm={12} md={12}>
          <FloorPlan />
        </Grid>
        <Grid xs={12} lg={9} sm={12} md={12}>
          <Price />
        </Grid>
        <Grid  xs={12} lg={9} sm={12} md={12}>
          <Map />
        </Grid>
      
        <Grid  xs={12} lg={9} sm={12} md={12}>
          <Video />
        </Grid>
        
        <Grid xs={12} lg={9} sm={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
