import React from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'animate.css';
import Dialog from "./Dialog"
import SL21 from "../assets/SL21.webp";
import SL22 from '../assets/SL22.webp';
import SL11 from '../assets/SL11.webp';
import SL12 from '../assets/SL12.webp';
import SL13 from '../assets/SL13.webp';
const Why = () => {
  return (
    <div>
   <section className="destination" id="destination" >

<div className="heading">
   
    <h1 style={{color:'black'}}>Why to Choose Parx Laureate ? </h1>
    <div style={{color:'black',fontSize:"16px"}}>Parx Laureate a new definition of ultra-luxurious residency at Noida Expressway. Parx Laureate is a huge residential project with all the basic and modern amenities with an impeccable design.</div>
</div>

<div className="box-container">

    <div className="box" data-aos="fade-up"  data-aos-delay="150" id="features">
        <div className="image" style={{height:"100%"}}>
        <Carousel
        autoFocus={true}
        autoPlay={true}
        infiniteLoop={true}
        
        >
                <div>
                    <img src={SL21} style={{zIndex:'-10',position:'relative'}}/>
                    
                </div>
                <div>
                    <img src={SL22} style={{zIndex:'-10',position:'relative'}}/>
                 
                    
                </div>
                <div>
                    <img src={SL13} style={{zIndex:'-10',position:'relative'}}/>
                 
                    
                </div>
                <div>
                    <img src={SL12} style={{zIndex:'-10',position:'relative'}}/>
                    
                </div>
               
               
            </Carousel>
            
        </div>
        </div>
        <div className="box" data-aos="fade-up"  data-aos-delay="150">
        <div className="image" style={{height:"280px",background:"white"}}>  
       <h3 style={{fontSize:"24px",marginTop:"0px"}}> Specifications  </h3>    
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",marginTop:"20px",lineHeight:"30px"}}><VerifiedIcon/>Every apartments faces a green space</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",lineHeight:"30px"}}><VerifiedIcon/>Vaastu compliant homes </div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",lineHeight:"30px"}}><VerifiedIcon/>An ultra-luxurious abode with 80% open area</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",lineHeight:"30px"}}><VerifiedIcon/>Low-density project with 3 flats per floor </div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",lineHeight:"30px"}}><VerifiedIcon/>Live security in 3-tiered security</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",lineHeight:"30px"}}><VerifiedIcon/>Imported Italian marble flooring in all rooms</div>

</div>
<div><Dialog /></div>
</div>
    </div>
</section>

    </div>
  )
}

export default Why