import React from 'react';
import Dialog from './Dialog';

const Price = () => {
  return (
   
    <section class="destination" id="destination" style={{marginTop:"1px"}}>

    <div class="heading" id="pricelist">
        
        <h2 style={{color:"black",fontSize:"25px",textDecoration:"underline"}}>Pricing List</h2>
    </div>

    <div class="box-container" >

        <div class="box" data-aos="fade-up" data-aos-delay="150" style={{border:"3px solid #82744b"}}>
            
            <div class="content">
                <h3 style={{color:"black"}}>3 BHK + 3 Toilets</h3>
                <p style={{fontSize:"20px"}}>(3302 & 3312) Sq. ft.</p>
                <div  className='price'><Dialog /></div>
            </div>
        </div>

        <div class="box" data-aos="fade-up" data-aos-delay="300" style={{border:"3px solid #82744b"}}>
           
            <div class="content">
                <h3 style={{color:"black"}}>4 BHK + 4 Toilets</h3>
                <p style={{fontSize:"20px"}}>(3995 & 4874) Sq. ft.</p>
                <div  className='price'><Dialog /></div>
            </div>
        </div>

        <div class="box" data-aos="fade-up" data-aos-delay="450" style={{border:"3px solid #82744b"}}>
          
            <div class="content">
                <h3 style={{color:"black"}}>Penthouse</h3>
                <p style={{fontSize:"20px"}}>(5365 - 6418) Sq. ft.</p>
                <div  className='price'><Dialog /></div>
            </div>
        </div>

        

       

       

    </div>

</section>

    
  )
}

export default Price