import React from 'react';
import Dialog from './Dialog1';
import Dialog2 from './Dialog2';
import Dialog3 from './Dialog3';
import FOBHK from '../assets/FOBHK.webp';
import TBHK from '../assets/TBHK.webp';
import PENTHOUSE from '../assets/PENTHOUSE.webp';

const FloorPlan = () => {
  return (
    
        <section className="destination" id="floorplan" >

<div className="heading" >
    
    <h2 style={{color:"black",fontSize:"25px",textDecoration:"underline"}}>Floor Plan</h2>
</div>

<div className="box-container" >

    <div className="box" data-aos="fade-up"  data-aos-delay="150">
        <div className="image">
            <img src={TBHK} alt="" style={{zIndex:"-5"}}/>
            <button className='button-one' ><Dialog/></button>  
        </div>
        
    </div>

    <div className="box" data-aos="fade-up" data-aos-delay="300">
        <div className="image">
            <img src={FOBHK} alt="" />
            <button className='button-one'><Dialog2 /></button>
        </div>
      
    </div>

 


    <div className="box" data-aos="fade-up" data-aos-delay="900">
        <div className="image">
            <img src={PENTHOUSE} alt="" />
            <button className='button-one'><Dialog3/></button>
        </div>
       
    </div>

    
  </div>

</section>





    
  )
}

export default FloorPlan