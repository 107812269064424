import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Dialog from '../Slider/Dialog';
const map = () => {
  return (
    <section className="destination" id="location" >

    <div className="heading">
       
        <h2 style={{color:"black",fontSize:"25px",textDecoration:"underline"}}>Parx Laureate: Location</h2>
    </div>
    
    <div className="box-container">
    
        <div className="box" data-aos="fade-up"  data-aos-delay="150">
            <div className="image" style={{height:"300px"}}>
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9915.003377381936!2d77.37180634145238!3d28.52519671161297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce61ed8fded39%3A0xfc125c7dc6778c7d!2sParx%20Laureate!5e0!3m2!1sen!2sin!4v1672655661402!5m2!1sen!2sin" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
            
            </div>
            </div>
            <div className="box" data-aos="fade-up"  data-aos-delay="150">
            <div className="image" style={{height:"300px",background:"white"}}>  
           <h3 style={{fontSize:"22px",marginLeft:"15px"}}>Unmatched location, Never-before Advantages</h3>    
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>Excellent location on the Expressway  </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>Golf Course in close vicinity </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>5 minute drive from Amity school</div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>10 minute drive from DND flyway </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"450px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>Proposed Metro connectivity </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px",lineHeight:"20px"}}><LocationOnIcon/>30 minute drive from proposed International Airport</div>
 
 <div><Dialog/></div>
 
  </div>
        </div>
    
       
        
    
     
    
    
    
    </div>
        
    
    
    </section>
  )
}

export default map