import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {Dialog} from '@mui/material'
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material';

const Sidebar = () => {
  const CustomButtonWrapper = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  }));
  const navigate=useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fm803ag', 'template_n62qg0m', form.current, 'sdsKJg8kQr7k_DIFw')
      .then((result) => {
          console.log(result.text);
          navigate("/Thankyou")
      }, (error) => {
          console.log(error.text);
      });
  };
      

    
      

  return (
    <CustomButtonWrapper>
      <div style={{position:"fixed",background:"white",height:"100%",width:"100%",marginTop:"150px"}}>
       <div style={{height:"80px",diplay:"flex",width:"320px",textAlign:"center"}}> <h1 style={{marginLeft:"15px",fontSize:"25px"}}>For Exclusive Offers & Benefits.</h1>
       </div>

       <form ref={form} onSubmit={sendEmail}>

   <div style={{border: '3px solid #82744b',
    borderRadius: '20px',
    padding:'5px',
    margin:'25px',
    width:'290px',
    height:'280px',
    background:'#fff5f5ba'}}>
   <div><h2 style={{textAlign:"center",color:"black"}}>Schedule a visit</h2></div>
    <div>
        
        <input type="text" name="user_name"  placeholder='enter your name' required style={{height:'35px',width:'250px',padding:'10px',margin:'10px',borderRadius:'20px',
    border:'1px solid #8a846d'}}/> 
        </div>
        <div>
        <input type="text"  name="contact_number" placeholder='Contact number' required title="Error Message" pattern="[1-9]{1}[0-9]{9}" style={{height:'35px',width:'250px',padding:'10px',margin:'10px',borderRadius:'20px',
    border:'1px solid #8a846d'}}/>
        </div>
        <div>
        <input type='email' name="user_email" placeholder='email id' required style={{height:'35px',width:'250px',padding:'10px',margin:'10px',borderRadius:'20px',
    border:'1px solid #8a846d'}}/>
        </div>
        <div>
        <input type="submit" value="Send" style={{
            background:'rgb(13, 24, 28)',
            color: 'rgb(231 200 122)',
            height: '30px',
            width: '100px',
            marginLeft: '80px',
            fontSize: '15px',
            borderRadius: '18px',
        }}/></div>
        </div>
      </form>
      </div>


    </CustomButtonWrapper>
  )
}

export default Sidebar;